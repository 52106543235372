import { create } from 'zustand'
import { GusObject, LoginData, Me } from '../types/auth'
import { callApi } from '../utils/callApi'
import { get as lodashGet } from 'lodash'
import { NotificationService } from '../service/NotificationService'

interface UpdateSettings {
  login: string
  password: string
  printerIpAddress: string
}

interface Store {
  me: Me | null
  login: (data: object) => Promise<void>
  logout: () => void
  updateSettings: (settings: object) => Promise<void>
  getDatFromGus: (nip: string) => Promise<GusObject>
}

const setPassword = (settings: object, me: Me) => {
  if (
    lodashGet(settings, 'newPassword', '') &&
    lodashGet(settings, 'oldPassword', '') === me.password
  ) {
    return lodashGet(settings, 'newPassword', '')
  } else {
    return me.password
  }
}

const useAuthStore = create<Store>((set, get) => ({
  me: null,
  login: async (data: object) => {
    await callApi('/api/login', 'POST', data)
      .then((user) => {
        if (!localStorage.getItem('loginData')) {
          const loginData: LoginData = {
            isLogin: true,
            timestamp: Date.now(),
            login: lodashGet(user, 'login', ''),
            password: lodashGet(user, 'password', ''),
          }

          localStorage.setItem('loginData', JSON.stringify(loginData))
        }
        return set(() => ({ me: user }))
      })
      .catch((e) => {
        NotificationService.showNotification(e.message, 'error')
        throw e
      })
  },
  logout: () => {
    localStorage.removeItem('loginData')
    return set(() => ({ me: null }))
  },
  updateSettings: async (settings: object) => {
    const data: UpdateSettings = {
      login: lodashGet(settings, 'login', ''),
      password: setPassword(settings, get().me as Me),
      printerIpAddress: lodashGet(settings, 'printerIpAddress', ''),
    }

    await callApi('/api/settings', 'PUT', data)
      .then((response) => {
        NotificationService.showNotification(response.message, 'success')

        if (response.isPasswordChanged) {
          get().logout()
        }
      })
      .catch((e) => {
        NotificationService.showNotification(e.message, 'error')
        throw e
      })
  },
  getDatFromGus: async (nip) => {
    return await callApi('/api/get-from-gus', 'POST', { nip: nip })
  },
}))

export default useAuthStore
