import React from 'react'
import { Button, ButtonProps, styled } from '@mui/material'

const CustomButton = styled(Button)(() => ({
  width: '300px',
}))

const MButton = (props: ButtonProps) => {
  return <CustomButton {...props} />
}

export default MButton
