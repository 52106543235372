import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import useOrdersStore from '../store/useOrdersStore'
import {
  Grid2,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { getServerUrl } from '../utils/helpers'
import { isNull } from 'lodash'
import dayjs from 'dayjs'

const Container = styled('div')(() => ({
  padding: '1rem',
  height: '100dvh',
  position: 'relative',
}))

const HeaderContainer = styled(Grid2)(() => ({
  marginBottom: '1rem',
}))

const OrderDetailPage = () => {
  const orderId = useParams().orderId
  const navigate = useNavigate()
  const { orders, getOrders } = useOrdersStore()
  const savedUserData = localStorage.getItem('loginData')

  useEffect(() => {
    getOrders()
  }, [])

  const orderDetails = orders.find((order) => order.id === Number(orderId))

  return (
    <Container>
      <HeaderContainer
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <IconButton
          onClick={() => {
            if (isNull(savedUserData)) {
              navigate('/login')
            } else {
              navigate(-2)
            }
          }}
        >
          <ArrowBackIcon style={{ color: '#000000' }} />
        </IconButton>
        <Typography variant="h5">Zlecenie: {orderDetails?.id}</Typography>
      </HeaderContainer>
      <Grid2
        container
        style={{ height: 'calc(100dvh - 88px)', overflowY: 'auto' }}
      >
        <Table>
          <TableBody>
            <TableRow>
              <TableCell width="30%" variant="head">
                Imię Nazwisko / Nazwa firmy
              </TableCell>
              <TableCell>{orderDetails?.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell width="30%" variant="head">
                NIP
              </TableCell>
              <TableCell>{orderDetails?.taxId}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell width="30%" variant="head">
                Kod pocztowy
              </TableCell>
              <TableCell>{orderDetails?.postalCode}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell width="30%" variant="head">
                Miejscowość
              </TableCell>
              <TableCell>{orderDetails?.city}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell width="30%" variant="head">
                Ulica
              </TableCell>
              <TableCell>{orderDetails?.road}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell width="30%" variant="head">
                Telefon kontaktowy
              </TableCell>
              <TableCell>{orderDetails?.phoneNumber}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell width="30%" variant="head">
                Kolor RAL
              </TableCell>
              <TableCell>{orderDetails?.ralColor}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell width="30%" variant="head">
                Podkład
              </TableCell>
              <TableCell>
                {orderDetails?.base === 'yes' ? 'Tak' : 'Nie'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell width="30%" variant="head">
                Piaskowanie
              </TableCell>
              <TableCell>
                {orderDetails?.sandblasting === 'yes' ? 'Tak' : 'Nie'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell width="30%" variant="head">
                PZ
              </TableCell>
              <TableCell>{orderDetails?.ciNumber}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell width="30%" variant="head">
                Data przyjęcia
              </TableCell>
              <TableCell>
                {dayjs(orderDetails?.date).format('YYYY-MM-DD HH:ss')}
              </TableCell>
            </TableRow>
            <TableRow style={{ height: '200px' }}>
              <TableCell width="30%" variant="head">
                Podpis zlecającego
              </TableCell>
              <TableCell>
                {orderDetails?.signature && (
                  <img
                    src={getServerUrl(orderDetails.signature)}
                    alt="signature"
                  />
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid2>
    </Container>
  )
}

export default OrderDetailPage
