import * as yup from 'yup'

function isValidIPv4(ip: string): boolean {
  const ipv4Regex =
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
  return ipv4Regex.test(ip)
}

export const loginValidation = yup.object().shape({
  login: yup.string().required('Login jest wymagany'),
  password: yup.string().required('Hasło jest wymagane'),
})

export const updateSettingsValidation = yup.object().shape({
  login: yup.string().required('Login jest wymagany'),
  oldPassword: yup.string(),
  newPassword: yup.string(),
  printerIpAddress: yup
    .string()
    .test('is-valid-ip', 'Błędny adres IP', (value) => {
      return value ? isValidIPv4(value) : false
    })
    .required('Adres IP jest wymagany'),
})

export const addOrderValidation = yup.object().shape({
  name: yup.string().required('Nazwa jest wymagana'),
  taxId: yup.string(),
  postalCode: yup.string().required('Kod pocztowy jest wymagany'),
  city: yup.string().required('Miasto jest wymagane'),
  road: yup.string().required('Ulica jest wymagana'),
  ralColor: yup.string().required('Kolor RAL jest wymagany'),
  ciNumber: yup.string().required('PZ jest wymagany'),
  date: yup.number().required('Data jest wymagana'),
  phoneNumber: yup.string().required('Numer telefonu jest wymagany'),
  base: yup.string().required('Podkład jest wymagany'),
  sandblasting: yup.string().required('Pisakowanie jest wymagane'),
  signature: yup.string(),
})
