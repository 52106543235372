import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import usePrinterStore from '../store/usePrinterStore'
import { Order } from '../types/order'
import MInput from './material/MInput'
import { toDataURL } from '../utils/helpers'

interface ConfirmPrintDialogProps {
  open: boolean
  closeDialog: () => void
  dataToPrint: Order | null
  navigateTo?: () => void
}

const ConfirmPrintDialog = ({
  open,
  closeDialog,
  dataToPrint,
  navigateTo,
}: ConfirmPrintDialogProps) => {
  const { printLabel } = usePrinterStore()
  const [numberOfCopy, setNumberOfCopy] = useState<string>('1')
  const [confirmCount, setConfirmCount] = useState<boolean>(false)
  const [signature, setSignature] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (dataToPrint?.signature) {
      toDataURL(dataToPrint.signature).then((res) =>
        setSignature(res as string),
      )
    }
  }, [dataToPrint])

  return (
    <Dialog open={open} onClose={() => closeDialog()}>
      <DialogTitle>Drukowanie</DialogTitle>
      <DialogContent style={{ padding: '1.25rem' }}>
        <Grid2 container direction="row" gap={2}>
          <MInput
            sx={{ width: '175px' }}
            label="Liczba kopii"
            type="number"
            value={numberOfCopy}
            onChange={(e) => setNumberOfCopy(e.target.value)}
          />
          {Number(numberOfCopy) >= 10 && (
            <MInput
              sx={{ width: '175px' }}
              label="Potwierdź liczbę kopii"
              type="number"
              defaultValue=""
              onChange={(e) =>
                setConfirmCount(Number(numberOfCopy) === Number(e.target.value))
              }
            />
          )}
        </Grid2>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => closeDialog()}>Anuluj</Button>
        <Button
          disabled={
            (Number(numberOfCopy) >= 10 && !confirmCount) || signature === null
          }
          onClick={() => {
            if (dataToPrint) {
              printLabel({
                ...dataToPrint,
                numberOfCopy: numberOfCopy,
                signature,
              }).then(() => {
                closeDialog()
                setSignature(undefined)
                if (navigateTo) {
                  navigateTo()
                }
              })
            }
          }}
        >
          Drukuj
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmPrintDialog
