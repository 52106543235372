import React from 'react'
import { Alert, Grid2, styled, Typography } from '@mui/material'
import useAuthStore from '../store/useAuthStore'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { updateSettingsValidation } from '../utils/validation'
import MInput from '../components/material/MInput'
import MButton from '../components/material/MButton'
import usePrinterStore from '../store/usePrinterStore'

const Container = styled('div')(() => ({
  padding: '1rem',
}))

const FormContainer = styled('form')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '300px',
}))

const HeaderContainer = styled(Grid2)(() => ({
  marginBottom: '2rem',
}))

const ButtonContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
}))

const SettingsPage = () => {
  const { me, updateSettings } = useAuthStore()
  const { printerStatuses, getPrinterStatus } = usePrinterStore()

  const { control, handleSubmit } = useForm({
    values: {
      login: me?.login || '',
      oldPassword: '',
      newPassword: '',
      printerIpAddress: me?.printerIpAddress || '',
    },
    resolver: yupResolver(updateSettingsValidation),
    mode: 'all',
  })

  const onSubmit = async (data: {
    login: string
    printerIpAddress: string
    oldPassword?: string
    newPassword?: string
  }) => {
    await updateSettings(data).finally(async () => {
      await getPrinterStatus()
    })
  }

  return (
    <Container>
      <HeaderContainer container direction="row" justifyContent="space-between">
        <Typography variant="h5">Ustawienia</Typography>
      </HeaderContainer>
      <Grid2 container direction="row" gap={4}>
        <FormContainer onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="login"
            control={control}
            render={({ field: { onChange, value }, formState: { errors } }) => (
              <MInput
                variant="outlined"
                label="Login"
                type="text"
                value={value}
                onChange={onChange}
                errorMessage={errors.login?.message}
              />
            )}
          />
          <Controller
            name="oldPassword"
            control={control}
            render={({ field: { onChange, value } }) => (
              <MInput
                variant="outlined"
                label="Stare hasło"
                type="password"
                value={value}
                onChange={onChange}
              />
            )}
          />
          <Controller
            name="newPassword"
            control={control}
            render={({ field: { onChange, value } }) => (
              <MInput
                variant="outlined"
                label="Nowe hasło"
                type="password"
                value={value}
                onChange={onChange}
              />
            )}
          />
          <Controller
            name="printerIpAddress"
            control={control}
            render={({ field: { onChange, value }, formState: { errors } }) => (
              <MInput
                variant="outlined"
                label="Adres IP drukarki"
                type="text"
                value={value}
                onChange={onChange}
                errorMessage={errors.printerIpAddress?.message}
              />
            )}
          />
          <ButtonContainer>
            <MButton
              style={{ width: '250px' }}
              type="submit"
              variant="contained"
            >
              Zaktualizuj ustawienia
            </MButton>
          </ButtonContainer>
        </FormContainer>
        <Grid2 container direction="column" alignItems="flex-end" flex={1}>
          {printerStatuses?.printerStatusMessage ? (
            <Alert variant="outlined" severity="success">
              Drukarka aktywna. Adres drukarki: {me?.printerIpAddress}
            </Alert>
          ) : (
            <Alert variant="outlined" severity="error">
              Drukarka nieaktywna. Sprawdź adres ip drukarki.
            </Alert>
          )}
          {printerStatuses?.printerStatusMessage &&
            !printerStatuses.paperStatusMessage && (
              <Alert variant="outlined" severity="warning">
                Uzupełnij etykiety.
              </Alert>
            )}
          {printerStatuses?.printerStatusMessage &&
            !printerStatuses.ribbonStatusMessage && (
              <Alert variant="outlined" severity="warning">
                Uzupełnij kalkę.
              </Alert>
            )}
        </Grid2>
      </Grid2>
    </Container>
  )
}

export default SettingsPage
