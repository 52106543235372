export const ralColorsPalette = {
  RAL1000: {
    description: 'Green beige',
    HEX: '#CDBA88',
    rgb: { r: 205, g: 186, b: 136 },
    group: 'yellow and beige',
  },
  RAL1001: {
    description: 'Beige',
    HEX: '#D0B084',
    rgb: { r: 208, g: 176, b: 132 },
    group: 'yellow and beige',
  },
  RAL1002: {
    description: 'Sand yellow',
    HEX: '#D2AA6D',
    rgb: { r: 210, g: 170, b: 109 },
    group: 'yellow and beige',
  },
  RAL1003: {
    description: 'Signal yellow',
    HEX: '#F9A800',
    rgb: { r: 249, g: 168, b: 0 },
    group: 'yellow and beige',
  },
  RAL1004: {
    description: 'Golden yellow',
    HEX: '#E49E00',
    rgb: { r: 228, g: 158, b: 0 },
    group: 'yellow and beige',
  },
  RAL1005: {
    description: 'Honey yellow',
    HEX: '#CB8E00',
    rgb: { r: 203, g: 142, b: 0 },
    group: 'yellow and beige',
  },
  RAL1006: {
    description: 'Maize yellow',
    HEX: '#E29000',
    rgb: { r: 226, g: 144, b: 0 },
    group: 'yellow and beige',
  },
  RAL1007: {
    description: 'Daffodil yellow',
    HEX: '#E88C00',
    rgb: { r: 232, g: 140, b: 0 },
    group: 'yellow and beige',
  },
  RAL1011: {
    description: 'Brown beige',
    HEX: '#AF804F',
    rgb: { r: 175, g: 128, b: 79 },
    group: 'yellow and beige',
  },
  RAL1012: {
    description: 'Lemon yellow',
    HEX: '#DDAF27',
    rgb: { r: 221, g: 175, b: 39 },
    group: 'yellow and beige',
  },
  RAL1013: {
    description: 'Oyster white',
    HEX: '#E3D9C6',
    rgb: { r: 227, g: 217, b: 198 },
    group: 'yellow and beige',
  },
  RAL1014: {
    description: 'Ivory',
    HEX: '#DDC49A',
    rgb: { r: 221, g: 196, b: 154 },
    group: 'yellow and beige',
  },
  RAL1015: {
    description: 'Light ivory',
    HEX: '#E6D2B5',
    rgb: { r: 230, g: 210, b: 181 },
    group: 'yellow and beige',
  },
  RAL1016: {
    description: 'Sulfur yellow',
    HEX: '#F1DD38',
    rgb: { r: 241, g: 221, b: 56 },
    group: 'yellow and beige',
  },
  RAL1017: {
    description: 'Saffron yellow',
    HEX: '#F6A950',
    rgb: { r: 246, g: 169, b: 80 },
    group: 'yellow and beige',
  },
  RAL1018: {
    description: 'Zinc yellow',
    HEX: '#FACA30',
    rgb: { r: 250, g: 202, b: 48 },
    group: 'yellow and beige',
  },
  RAL1019: {
    description: 'Grey beige',
    HEX: '#A48F7A',
    rgb: { r: 164, g: 143, b: 122 },
    group: 'yellow and beige',
  },
  RAL1020: {
    description: 'Olive yellow',
    HEX: '#A08F65',
    rgb: { r: 160, g: 143, b: 101 },
    group: 'yellow and beige',
  },
  RAL1021: {
    description: 'Colza yellow',
    HEX: '#F6B600',
    rgb: { r: 246, g: 182, b: 0 },
    group: 'yellow and beige',
  },
  RAL1023: {
    description: 'Traffic yellow',
    HEX: '#F7B500',
    rgb: { r: 247, g: 181, b: 0 },
    group: 'yellow and beige',
  },
  RAL1024: {
    description: 'Ochre yellow',
    HEX: '#BA8F4C',
    rgb: { r: 186, g: 143, b: 76 },
    group: 'yellow and beige',
  },
  RAL1026: {
    description: 'Luminous yellow',
    HEX: '#FFFF00',
    rgb: { r: 255, g: 255, b: 0 },
    group: 'yellow and beige',
  },
  RAL1027: {
    description: 'Curry',
    HEX: '#A77F0E',
    rgb: { r: 167, g: 127, b: 14 },
    group: 'yellow and beige',
  },
  RAL1028: {
    description: 'Melon yellow',
    HEX: '#FF9B00',
    rgb: { r: 255, g: 155, b: 0 },
    group: 'yellow and beige',
  },
  RAL1032: {
    description: 'Broom yellow',
    HEX: '#E2A300',
    rgb: { r: 226, g: 163, b: 0 },
    group: 'yellow and beige',
  },
  RAL1033: {
    description: 'Dahlia yellow',
    HEX: '#F99A1C',
    rgb: { r: 249, g: 154, b: 28 },
    group: 'yellow and beige',
  },
  RAL1034: {
    description: 'Pastel yellow',
    HEX: '#EB9C52',
    rgb: { r: 235, g: 156, b: 82 },
    group: 'yellow and beige',
  },
  RAL1035: {
    description: 'Pearl beige',
    HEX: '#908370',
    rgb: { r: 144, g: 131, b: 112 },
    group: 'yellow and beige',
  },
  RAL1036: {
    description: 'Pearl gold',
    HEX: '#80643F',
    rgb: { r: 128, g: 100, b: 63 },
    group: 'yellow and beige',
  },
  RAL1037: {
    description: 'Sun yellow',
    HEX: '#F09200',
    rgb: { r: 240, g: 146, b: 0 },
    group: 'yellow and beige',
  },
  RAL2000: {
    description: 'Yellow orange',
    HEX: '#DD7907',
    rgb: { r: 213, g: 111, b: 0 },
    group: 'orange',
  },
  RAL2001: {
    description: 'Red orange',
    HEX: '#BE4E20',
    rgb: { r: 182, g: 72, b: 28 },
    group: 'orange',
  },
  RAL2002: {
    description: 'Vermilion',
    HEX: '#C63927',
    rgb: { r: 188, g: 56, b: 35 },
    group: 'orange',
  },
  RAL2003: {
    description: 'Pastel orange',
    HEX: '#FA842B',
    rgb: { r: 241, g: 120, b: 41 },
    group: 'orange',
  },
  RAL2004: {
    description: 'Pure orange',
    HEX: '#E75B12',
    rgb: { r: 222, g: 83, b: 6 },
    group: 'orange',
  },
  RAL2005: {
    description: 'Luminous orange',
    HEX: '#FF2300',
    rgb: { r: 255, g: 75, b: 17 },
    group: 'orange',
  },
  RAL2007: {
    description: 'Luminous bright orange',
    HEX: '#FFA421',
    rgb: { r: 255, g: 183, b: 0 },
    group: 'orange',
  },
  RAL2008: {
    description: 'Bright red orange',
    HEX: '#F3752C',
    rgb: { r: 232, g: 107, b: 34 },
    group: 'orange',
  },
  RAL2009: {
    description: 'Traffic orange',
    HEX: '#E15501',
    rgb: { r: 218, g: 83, b: 10 },
    group: 'orange',
  },
  RAL2010: {
    description: 'Signal orange',
    HEX: '#D4652F',
    rgb: { r: 204, g: 93, b: 41 },
    group: 'orange',
  },
  RAL2011: {
    description: 'Deep orange',
    HEX: '#EC7C25',
    rgb: { r: 221, g: 110, b: 15 },
    group: 'orange',
  },
  RAL2012: {
    description: 'Salmon orange',
    HEX: '#DB6A50',
    rgb: { r: 209, g: 101, b: 78 },
    group: 'orange',
  },
  RAL2013: {
    description: 'Pearl orange',
    HEX: '#954527',
    rgb: { r: 143, g: 62, b: 38 },
    group: 'orange',
  },
  RAL2017: {
    description: 'RAL orange',
    HEX: '#FA4402',
    rgb: { r: 250, g: 68, b: 2 },
    group: 'orange',
  },
  RAL3000: {
    description: 'Flame red',
    HEX: '#AB2524',
    rgb: { r: 164, g: 40, b: 33 },
    group: 'red',
  },
  RAL3001: {
    description: 'Signal red',
    HEX: '#A02128',
    rgb: { r: 152, g: 35, b: 35 },
    group: 'red',
  },
  RAL3002: {
    description: 'Carmine red',
    HEX: '#A1232B',
    rgb: { r: 152, g: 34, b: 34 },
    group: 'red',
  },
  RAL3003: {
    description: 'Ruby red',
    HEX: '#8D1D2C',
    rgb: { r: 132, g: 25, b: 34 },
    group: 'red',
  },
  RAL3004: {
    description: 'Purple red',
    HEX: '#701F29',
    rgb: { r: 105, g: 27, b: 35 },
    group: 'red',
  },
  RAL3005: {
    description: 'Wine red',
    HEX: '#5E2028',
    rgb: { r: 88, g: 24, b: 31 },
    group: 'red',
  },
  RAL3007: {
    description: 'Black red',
    HEX: '#402225',
    rgb: { r: 61, g: 32, b: 34 },
    group: 'red',
  },
  RAL3009: {
    description: 'Oxide red',
    HEX: '#703731',
    rgb: { r: 102, g: 48, b: 41 },
    group: 'red',
  },
  RAL3011: {
    description: 'Brown red',
    HEX: '#7E292C',
    rgb: { r: 119, g: 36, b: 36 },
    group: 'red',
  },
  RAL3012: {
    description: 'Beige red',
    HEX: '#CB8D73',
    rgb: { r: 194, g: 133, b: 109 },
    group: 'red',
  },
  RAL3013: {
    description: 'Tomato red',
    HEX: '#9C322E',
    rgb: { r: 149, g: 46, b: 37 },
    group: 'red',
  },
  RAL3014: {
    description: 'Antique pink',
    HEX: '#D47479',
    rgb: { r: 201, g: 115, b: 117 },
    group: 'red',
  },
  RAL3015: {
    description: 'Light pink',
    HEX: '#E1A6AD',
    rgb: { r: 215, g: 160, b: 166 },
    group: 'red',
  },
  RAL3016: {
    description: 'Coral red',
    HEX: '#AC4034',
    rgb: { r: 164, g: 60, b: 48 },
    group: 'red',
  },
  RAL3017: {
    description: 'Rose',
    HEX: '#D3545F',
    rgb: { r: 200, g: 84, b: 93 },
    group: 'red',
  },
  RAL3018: {
    description: 'Strawberry red',
    HEX: '#D14152',
    rgb: { r: 196, g: 62, b: 74 },
    group: 'red',
  },
  RAL3020: {
    description: 'Traffic red',
    HEX: '#C1121C',
    rgb: { r: 184, g: 29, b: 19 },
    group: 'red',
  },
  RAL3022: {
    description: 'Salmon pink',
    HEX: '#D56D56',
    rgb: { r: 204, g: 105, b: 85 },
    group: 'red',
  },
  RAL3024: {
    description: 'Luminous red',
    HEX: '#F70000',
    rgb: { r: 255, g: 42, b: 36 },
    group: 'red',
  },
  RAL3026: {
    description: 'Luminous bright red',
    HEX: '#FF0000',
    rgb: { r: 255, g: 38, b: 32 },
    group: 'red',
  },
  RAL3027: {
    description: 'Raspberry red',
    HEX: '#B42041',
    rgb: { r: 169, g: 38, b: 61 },
    group: 'red',
  },
  RAL3028: {
    description: 'Pure red',
    HEX: '#E72512',
    rgb: { r: 201, g: 43, b: 38 },
    group: 'red',
  },
  RAL3031: {
    description: 'Orient red',
    HEX: '#AC323B',
    rgb: { r: 164, g: 51, b: 56 },
    group: 'red',
  },
  RAL3032: {
    description: 'Pearl ruby red',
    HEX: '#711521',
    rgb: { r: 110, g: 28, b: 36 },
    group: 'red',
  },
  RAL3033: {
    description: 'Pearl pink',
    HEX: '#B24C43',
    rgb: { r: 162, g: 57, b: 46 },
    group: 'red',
  },
  RAL4001: {
    description: 'Red lilac',
    HEX: '#8A5A83',
    rgb: { r: 131, g: 96, b: 131 },
    group: 'violet',
  },
  RAL4002: {
    description: 'Red violet',
    HEX: '#933D50',
    rgb: { r: 140, g: 60, b: 75 },
    group: 'violet',
  },
  RAL4003: {
    description: 'Heather violet',
    HEX: '#D15B8F',
    rgb: { r: 196, g: 96, b: 140 },
    group: 'violet',
  },
  RAL4004: {
    description: 'Claret violet',
    HEX: '#691639',
    rgb: { r: 100, g: 29, b: 57 },
    group: 'violet',
  },
  RAL4005: {
    description: 'Blue lilac',
    HEX: '#83639D',
    rgb: { r: 123, g: 103, b: 154 },
    group: 'violet',
  },
  RAL4006: {
    description: 'Traffic purple',
    HEX: '#992572',
    rgb: { r: 145, g: 48, b: 115 },
    group: 'violet',
  },
  RAL4007: {
    description: 'Purple violet',
    HEX: '#4A203B',
    rgb: { r: 71, g: 36, b: 60 },
    group: 'violet',
  },
  RAL4008: {
    description: 'Signal violet',
    HEX: '#904684',
    rgb: { r: 135, g: 75, b: 131 },
    group: 'violet',
  },
  RAL4009: {
    description: 'Pastel violet',
    HEX: '#A38995',
    rgb: { r: 157, g: 133, b: 146 },
    group: 'violet',
  },
  RAL4010: {
    description: 'Telemagenta',
    HEX: '#C63678',
    rgb: { r: 187, g: 62, b: 119 },
    group: 'violet',
  },
  RAL4011: {
    description: 'Pearl violet',
    HEX: '#8773A1',
    rgb: { r: 113, g: 98, b: 135 },
    group: 'violet',
  },
  RAL4012: {
    description: 'Pearl blackberry',
    HEX: '#6B6880',
    rgb: { r: 109, g: 107, b: 127 },
    group: 'violet',
  },
  RAL5000: {
    description: 'Violet blue',
    HEX: '#384C70',
    rgb: { r: 56, g: 78, b: 111 },
    group: 'blue',
  },
  RAL5001: {
    description: 'Green blue',
    HEX: '#1F4764',
    rgb: { r: 29, g: 76, b: 100 },
    group: 'blue',
  },
  RAL5002: {
    description: 'Ultramarine blue',
    HEX: '#2B2C7C',
    rgb: { r: 30, g: 54, b: 123 },
    group: 'blue',
  },
  RAL5003: {
    description: 'Sapphire blue',
    HEX: '#2A3756',
    rgb: { r: 38, g: 56, b: 85 },
    group: 'blue',
  },
  RAL5004: {
    description: 'Black blue',
    HEX: '#1D1F2A',
    rgb: { r: 26, g: 30, b: 40 },
    group: 'blue',
  },
  RAL5005: {
    description: 'Signal blue',
    HEX: '#154889',
    rgb: { r: 0, g: 81, b: 135 },
    group: 'blue',
  },
  RAL5007: {
    description: 'Brilliant blue',
    HEX: '#41678D',
    rgb: { r: 66, g: 106, b: 140 },
    group: 'blue',
  },
  RAL5008: {
    description: 'Grey blue',
    HEX: '#313C48',
    rgb: { r: 45, g: 58, b: 68 },
    group: 'blue',
  },
  RAL5009: {
    description: 'Azure blue',
    HEX: '#2E5978',
    rgb: { r: 45, g: 94, b: 120 },
    group: 'blue',
  },
  RAL5010: {
    description: 'Gentian blue',
    HEX: '#13447C',
    rgb: { r: 0, g: 78, b: 124 },
    group: 'blue',
  },
  RAL5011: {
    description: 'Steel blue',
    HEX: '#232C3F',
    rgb: { r: 30, g: 43, b: 61 },
    group: 'blue',
  },
  RAL5012: {
    description: 'Light blue',
    HEX: '#3481B8',
    rgb: { r: 46, g: 136, b: 182 },
    group: 'blue',
  },
  RAL5013: {
    description: 'Cobalt blue',
    HEX: '#232D53',
    rgb: { r: 34, g: 48, b: 83 },
    group: 'blue',
  },
  RAL5014: {
    description: 'Pigeon blue',
    HEX: '#6C7C98',
    rgb: { r: 104, g: 124, b: 150 },
    group: 'blue',
  },
  RAL5015: {
    description: 'Sky blue',
    HEX: '#2874B2',
    rgb: { r: 11, g: 123, b: 176 },
    group: 'blue',
  },
  RAL5017: {
    description: 'Traffic blue',
    HEX: '#0E518D',
    rgb: { r: 0, g: 90, b: 140 },
    group: 'blue',
  },
  RAL5018: {
    description: 'Turquoise blue',
    HEX: '#21888F',
    rgb: { r: 27, g: 139, b: 140 },
    group: 'blue',
  },
  RAL5019: {
    description: 'Capri blue',
    HEX: '#1A5784',
    rgb: { r: 15, g: 93, b: 132 },
    group: 'blue',
  },
  RAL5020: {
    description: 'Ocean blue',
    HEX: '#0B4151',
    rgb: { r: 0, g: 65, b: 75 },
    group: 'blue',
  },
  RAL5021: {
    description: 'Water blue',
    HEX: '#07737A',
    rgb: { r: 0, g: 117, b: 119 },
    group: 'blue',
  },
  RAL5022: {
    description: 'Night blue',
    HEX: '#2F2A5A',
    rgb: { r: 43, g: 44, b: 90 },
    group: 'blue',
  },
  RAL5023: {
    description: 'Distant blue',
    HEX: '#4D668E',
    rgb: { r: 74, g: 104, b: 141 },
    group: 'blue',
  },
  RAL5024: {
    description: 'Pastel blue',
    HEX: '#6A93B0',
    rgb: { r: 103, g: 146, b: 172 },
    group: 'blue',
  },
  RAL5025: {
    description: 'Pearl Gentian blue',
    HEX: '#296478',
    rgb: { r: 44, g: 105, b: 124 },
    group: 'blue',
  },
  RAL5026: {
    description: 'Pearl night blue',
    HEX: '#102C54',
    rgb: { r: 27, g: 47, b: 82 },
    group: 'blue',
  },
  RAL6000: {
    description: 'Patina green',
    HEX: '#327662',
    rgb: { r: 59, g: 116, b: 96 },
    group: 'green',
  },
  RAL6001: {
    description: 'Emerald green',
    HEX: '#28713E',
    rgb: { r: 49, g: 104, b: 52 },
    group: 'green',
  },
  RAL6002: {
    description: 'Leaf green',
    HEX: '#276235',
    rgb: { r: 45, g: 90, b: 39 },
    group: 'green',
  },
  RAL6003: {
    description: 'Olive green',
    HEX: '#4B573E',
    rgb: { r: 78, g: 83, b: 59 },
    group: 'green',
  },
  RAL6004: {
    description: 'Blue green',
    HEX: '#0E4243',
    rgb: { r: 8, g: 68, b: 66 },
    group: 'green',
  },
  RAL6005: {
    description: 'Moss green',
    HEX: '#0F4336',
    rgb: { r: 17, g: 66, b: 50 },
    group: 'green',
  },
  RAL6006: {
    description: 'Grey olive',
    HEX: '#40433B',
    rgb: { r: 59, g: 57, b: 46 },
    group: 'green',
  },
  RAL6007: {
    description: 'Bottle green',
    HEX: '#283424',
    rgb: { r: 42, g: 50, b: 34 },
    group: 'green',
  },
  RAL6008: {
    description: 'Brown green',
    HEX: '#35382E',
    rgb: { r: 54, g: 52, b: 42 },
    group: 'green',
  },
  RAL6009: {
    description: 'Fir green',
    HEX: '#26392F',
    rgb: { r: 39, g: 54, b: 42 },
    group: 'green',
  },
  RAL6010: {
    description: 'Grass green',
    HEX: '#3E753B',
    rgb: { r: 72, g: 111, b: 56 },
    group: 'green',
  },
  RAL6011: {
    description: 'Reseda green',
    HEX: '#68825B',
    rgb: { r: 105, g: 125, b: 88 },
    group: 'green',
  },
  RAL6012: {
    description: 'Black green',
    HEX: '#31403D',
    rgb: { r: 48, g: 61, b: 58 },
    group: 'green',
  },
  RAL6013: {
    description: 'Reed green',
    HEX: '#797C5A',
    rgb: { r: 122, g: 118, b: 90 },
    group: 'green',
  },
  RAL6014: {
    description: 'Yellow olive',
    HEX: '#444337',
    rgb: { r: 70, g: 65, b: 53 },
    group: 'green',
  },
  RAL6015: {
    description: 'Black olive',
    HEX: '#3D403A',
    rgb: { r: 60, g: 61, b: 54 },
    group: 'green',
  },
  RAL6016: {
    description: 'Turquoise green',
    HEX: '#026A52',
    rgb: { r: 0, g: 106, b: 76 },
    group: 'green',
  },
  RAL6017: {
    description: 'May green',
    HEX: '#468641',
    rgb: { r: 83, g: 128, b: 63 },
    group: 'green',
  },
  RAL6018: {
    description: 'Yellow green',
    HEX: '#48A43F',
    rgb: { r: 89, g: 154, b: 57 },
    group: 'green',
  },
  RAL6019: {
    description: 'Pastel green',
    HEX: '#B7D9B1',
    rgb: { r: 183, g: 206, b: 172 },
    group: 'green',
  },
  RAL6020: {
    description: 'Chrome green',
    HEX: '#354733',
    rgb: { r: 54, g: 66, b: 47 },
    group: 'green',
  },
  RAL6021: {
    description: 'Pale green',
    HEX: '#86A47C',
    rgb: { r: 135, g: 154, b: 119 },
    group: 'green',
  },
  RAL6022: {
    description: 'Olive-drab/brown olive',
    HEX: '#3E3C32',
    rgb: { r: 57, g: 51, b: 39 },
    group: 'green',
  },
  RAL6024: {
    description: 'Traffic green',
    HEX: '#008754',
    rgb: { r: 0, g: 132, b: 80 },
    group: 'green',
  },
  RAL6025: {
    description: 'Fern green',
    HEX: '#53753C',
    rgb: { r: 90, g: 110, b: 59 },
    group: 'green',
  },
  RAL6026: {
    description: 'Opal green',
    HEX: '#005D52',
    rgb: { r: 0, g: 95, b: 78 },
    group: 'green',
  },
  RAL6027: {
    description: 'Light green',
    HEX: '#81C0BB',
    rgb: { r: 128, g: 186, b: 181 },
    group: 'green',
  },
  RAL6028: {
    description: 'Pine green',
    HEX: '#2D5546',
    rgb: { r: 48, g: 84, b: 66 },
    group: 'green',
  },
  RAL6029: {
    description: 'Mint green',
    HEX: '#007243',
    rgb: { r: 0, g: 112, b: 60 },
    group: 'green',
  },
  RAL6032: {
    description: 'Signal green',
    HEX: '#0F8558',
    rgb: { r: 28, g: 128, b: 81 },
    group: 'green',
  },
  RAL6033: {
    description: 'Mint turquoise',
    HEX: '#478A84',
    rgb: { r: 72, g: 135, b: 127 },
    group: 'green',
  },
  RAL6034: {
    description: 'Pastel turquoise',
    HEX: '#7FB0B2',
    rgb: { r: 124, g: 173, b: 172 },
    group: 'green',
  },
  RAL6035: {
    description: 'Pearl green',
    HEX: '#1B542C',
    rgb: { r: 19, g: 77, b: 36 },
    group: 'green',
  },
  RAL6036: {
    description: 'Pearl opal green',
    HEX: '#005D4C',
    rgb: { r: 7, g: 88, b: 75 },
    group: 'green',
  },
  RAL6037: {
    description: 'Pure green',
    HEX: '#25E712',
    rgb: { r: 0, g: 140, b: 39 },
    group: 'green',
  },
  RAL6038: {
    description: 'Luminous green',
    HEX: '#00F700',
    rgb: { r: 0, g: 182, b: 18 },
    group: 'green',
  },
  RAL7000: {
    description: 'Squirrel grey',
    HEX: '#7E8B92',
    rgb: { r: 123, g: 136, b: 142 },
    group: 'grey',
  },
  RAL7001: {
    description: 'Silver grey',
    HEX: '#8F999F',
    rgb: { r: 142, g: 150, b: 157 },
    group: 'grey',
  },
  RAL7002: {
    description: 'Olive grey',
    HEX: '#817F68',
    rgb: { r: 127, g: 120, b: 99 },
    group: 'grey',
  },
  RAL7003: {
    description: 'Moss grey',
    HEX: '#7A7B6D',
    rgb: { r: 120, g: 119, b: 105 },
    group: 'grey',
  },
  RAL7004: {
    description: 'Signal grey',
    HEX: '#9EA0A1',
    rgb: { r: 155, g: 155, b: 155 },
    group: 'grey',
  },
  RAL7005: {
    description: 'Mouse grey',
    HEX: '#6B716F',
    rgb: { r: 107, g: 110, b: 107 },
    group: 'grey',
  },
  RAL7006: {
    description: 'Beige grey',
    HEX: '#756F61',
    rgb: { r: 117, g: 106, b: 94 },
    group: 'grey',
  },
  RAL7008: {
    description: 'Khaki grey',
    HEX: '#746643',
    rgb: { r: 114, g: 95, b: 60 },
    group: 'grey',
  },
  RAL7009: {
    description: 'Green grey',
    HEX: '#5B6259',
    rgb: { r: 92, g: 96, b: 88 },
    group: 'grey',
  },
  RAL7010: {
    description: 'Tarpaulin grey',
    HEX: '#575D57',
    rgb: { r: 88, g: 92, b: 86 },
    group: 'grey',
  },
  RAL7011: {
    description: 'Iron grey',
    HEX: '#555D61',
    rgb: { r: 83, g: 89, b: 93 },
    group: 'grey',
  },
  RAL7012: {
    description: 'Basalt grey',
    HEX: '#596163',
    rgb: { r: 88, g: 93, b: 94 },
    group: 'grey',
  },
  RAL7013: {
    description: 'Brown grey',
    HEX: '#555548',
    rgb: { r: 86, g: 80, b: 68 },
    group: 'grey',
  },
  RAL7015: {
    description: 'Slate grey',
    HEX: '#51565C',
    rgb: { r: 80, g: 83, b: 89 },
    group: 'grey',
  },
  RAL7016: {
    description: 'Anthracite grey',
    HEX: '#373F43',
    rgb: { r: 56, g: 62, b: 66 },
    group: 'grey',
  },
  RAL7021: {
    description: 'Black grey',
    HEX: '#2E3234',
    rgb: { r: 48, g: 50, b: 52 },
    group: 'grey',
  },
  RAL7022: {
    description: 'Umbra grey',
    HEX: '#4B4D46',
    rgb: { r: 76, g: 74, b: 68 },
    group: 'grey',
  },
  RAL7023: {
    description: 'Concrete grey',
    HEX: '#818479',
    rgb: { r: 127, g: 128, b: 118 },
    group: 'grey',
  },
  RAL7024: {
    description: 'Graphite grey',
    HEX: '#474A50',
    rgb: { r: 70, g: 73, b: 79 },
    group: 'grey',
  },
  RAL7026: {
    description: 'Granite grey',
    HEX: '#374447',
    rgb: { r: 56, g: 67, b: 69 },
    group: 'grey',
  },
  RAL7030: {
    description: 'Stone grey',
    HEX: '#939388',
    rgb: { r: 145, g: 142, b: 133 },
    group: 'grey',
  },
  RAL7031: {
    description: 'Blue grey',
    HEX: '#5D6970',
    rgb: { r: 93, g: 104, b: 109 },
    group: 'grey',
  },
  RAL7032: {
    description: 'Pebble grey',
    HEX: '#B9B9A8',
    rgb: { r: 180, g: 176, b: 161 },
    group: 'grey',
  },
  RAL7033: {
    description: 'Cement grey',
    HEX: '#818979',
    rgb: { r: 126, g: 130, b: 116 },
    group: 'grey',
  },
  RAL7034: {
    description: 'Yellow grey',
    HEX: '#939176',
    rgb: { r: 144, g: 136, b: 111 },
    group: 'grey',
  },
  RAL7035: {
    description: 'Light grey',
    HEX: '#CBD0CC',
    rgb: { r: 197, g: 199, b: 196 },
    group: 'grey',
  },
  RAL7036: {
    description: 'Platinum grey',
    HEX: '#9A9697',
    rgb: { r: 151, g: 147, b: 146 },
    group: 'grey',
  },
  RAL7037: {
    description: 'Dusty grey',
    HEX: '#7C7F7E',
    rgb: { r: 122, g: 123, b: 122 },
    group: 'grey',
  },
  RAL7038: {
    description: 'Agate grey',
    HEX: '#B4B8B0',
    rgb: { r: 175, g: 177, b: 169 },
    group: 'grey',
  },
  RAL7039: {
    description: 'Quartz grey',
    HEX: '#6B695F',
    rgb: { r: 106, g: 102, b: 94 },
    group: 'grey',
  },
  RAL7040: {
    description: 'Window grey',
    HEX: '#9DA3A6',
    rgb: { r: 152, g: 158, b: 161 },
    group: 'grey',
  },
  RAL7042: {
    description: 'Traffic grey A',
    HEX: '#8F9695',
    rgb: { r: 142, g: 146, b: 145 },
    group: 'grey',
  },
  RAL7043: {
    description: 'Traffic grey B',
    HEX: '#4E5451',
    rgb: { r: 79, g: 82, b: 80 },
    group: 'grey',
  },
  RAL7044: {
    description: 'Silk grey',
    HEX: '#BDBDB2',
    rgb: { r: 182, g: 179, b: 168 },
    group: 'grey',
  },
  RAL7045: {
    description: 'Telegrey 1',
    HEX: '#91969A',
    rgb: { r: 142, g: 146, b: 149 },
    group: 'grey',
  },
  RAL7046: {
    description: 'Telegrey 2',
    HEX: '#82898E',
    rgb: { r: 127, g: 134, b: 138 },
    group: 'grey',
  },
  RAL7047: {
    description: 'Telegrey 4',
    HEX: '#CFD0CF',
    rgb: { r: 200, g: 200, b: 199 },
    group: 'grey',
  },
  RAL7048: {
    description: 'Pearl mouse grey',
    HEX: '#888175',
    rgb: { r: 128, g: 123, b: 115 },
    group: 'grey',
  },
  RAL8000: {
    description: 'Green brown',
    HEX: '#887142',
    rgb: { r: 134, g: 106, b: 62 },
    group: 'brown',
  },
  RAL8001: {
    description: 'Ochre brown',
    HEX: '#9C6B30',
    rgb: { r: 153, g: 99, b: 43 },
    group: 'brown',
  },
  RAL8002: {
    description: 'Signal brown',
    HEX: '#7B5141',
    rgb: { r: 119, g: 77, b: 62 },
    group: 'brown',
  },
  RAL8003: {
    description: 'Clay brown',
    HEX: '#80542F',
    rgb: { r: 124, g: 75, b: 39 },
    group: 'brown',
  },
  RAL8004: {
    description: 'Copper brown',
    HEX: '#8F4E35',
    rgb: { r: 138, g: 73, b: 49 },
    group: 'brown',
  },
  RAL8007: {
    description: 'Fawn brown',
    HEX: '#6F4A2F',
    rgb: { r: 109, g: 70, b: 43 },
    group: 'brown',
  },
  RAL8008: {
    description: 'Olive brown',
    HEX: '#6F4F28',
    rgb: { r: 111, g: 74, b: 37 },
    group: 'brown',
  },
  RAL8011: {
    description: 'Nut brown',
    HEX: '#5A3A29',
    rgb: { r: 88, g: 56, b: 39 },
    group: 'brown',
  },
  RAL8012: {
    description: 'Red brown',
    HEX: '#673831',
    rgb: { r: 100, g: 51, b: 43 },
    group: 'brown',
  },
  RAL8014: {
    description: 'Sepia brown',
    HEX: '#49392D',
    rgb: { r: 72, g: 53, b: 38 },
    group: 'brown',
  },
  RAL8015: {
    description: 'Chestnut brown',
    HEX: '#633A34',
    rgb: { r: 93, g: 47, b: 39 },
    group: 'brown',
  },
  RAL8016: {
    description: 'Mahogany brown',
    HEX: '#4C2F26',
    rgb: { r: 75, g: 43, b: 32 },
    group: 'brown',
  },
  RAL8017: {
    description: 'Chocolate brown',
    HEX: '#44322D',
    rgb: { r: 67, g: 47, b: 41 },
    group: 'brown',
  },
  RAL8019: {
    description: 'Grey brown',
    HEX: '#3F3A3A',
    rgb: { r: 61, g: 54, b: 53 },
    group: 'brown',
  },
  RAL8022: {
    description: 'Black brown',
    HEX: '#211F20',
    rgb: { r: 26, g: 23, b: 25 },
    group: 'brown',
  },
  RAL8023: {
    description: 'Orange brown',
    HEX: '#A65E2F',
    rgb: { r: 160, g: 87, b: 41 },
    group: 'brown',
  },
  RAL8024: {
    description: 'Beige brown',
    HEX: '#79553C',
    rgb: { r: 118, g: 80, b: 56 },
    group: 'brown',
  },
  RAL8025: {
    description: 'Pale brown',
    HEX: '#755C49',
    rgb: { r: 115, g: 88, b: 71 },
    group: 'brown',
  },
  RAL8028: {
    description: 'Terra brown',
    HEX: '#4E3B2B',
    rgb: { r: 79, g: 58, b: 42 },
    group: 'brown',
  },
  RAL8029: {
    description: 'Pearl copper',
    HEX: '#773C27',
    rgb: { r: 125, g: 64, b: 49 },
    group: 'brown',
  },
  RAL9001: {
    description: 'Cream',
    HEX: '#EFEBDC',
    rgb: { r: 233, g: 224, b: 210 },
    group: 'white and black',
  },
  RAL9002: {
    description: 'Grey white',
    HEX: '#DDDED4',
    rgb: { r: 215, g: 213, b: 203 },
    group: 'white and black',
  },
  RAL9003: {
    description: 'Signal white',
    HEX: '#F4F8F4',
    rgb: { r: 236, g: 236, b: 231 },
    group: 'white and black',
  },
  RAL9004: {
    description: 'Signal black',
    HEX: '#2E3032',
    rgb: { r: 43, g: 43, b: 44 },
    group: 'white and black',
  },
  RAL9005: {
    description: 'Jet black',
    HEX: '#0A0A0D',
    rgb: { r: 14, g: 14, b: 16 },
    group: 'white and black',
  },
  RAL9006: {
    description: 'White aluminium',
    HEX: '#A5A8A6',
    rgb: { r: 161, g: 161, b: 160 },
    group: 'white and black',
  },
  RAL9007: {
    description: 'Grey aluminium',
    HEX: '#8F8F8C',
    rgb: { r: 135, g: 133, b: 129 },
    group: 'white and black',
  },
  RAL9010: {
    description: 'Pure white',
    HEX: '#F7F9EF',
    rgb: { r: 241, g: 236, b: 225 },
    group: 'white and black',
  },
  RAL9011: {
    description: 'Graphite black',
    HEX: '#292C2F',
    rgb: { r: 39, g: 41, b: 43 },
    group: 'white and black',
  },
  RAL9012: {
    description: 'Clean room white',
    HEX: '#FFFDE6',
    rgb: { r: 255, g: 253, b: 230 },
    group: 'white and black',
  },
  RAL9016: {
    description: 'Traffic white',
    HEX: '#F7FBF5',
    rgb: { r: 241, g: 240, b: 234 },
    group: 'white and black',
  },
  RAL9017: {
    description: 'Traffic black',
    HEX: '#2A2D2F',
    rgb: { r: 42, g: 41, b: 42 },
    group: 'white and black',
  },
  RAL9018: {
    description: 'Papyrus white',
    HEX: '#CFD3CD',
    rgb: { r: 200, g: 203, b: 196 },
    group: 'white and black',
  },
  RAL9022: {
    description: 'Pearl light grey',
    HEX: '#9C9C9C',
    rgb: { r: 133, g: 133, b: 131 },
    group: 'white and black',
  },
  RAL9023: {
    description: 'Pearl dark grey',
    HEX: '#7E8182',
    rgb: { r: 121, g: 123, b: 122 },
    group: 'white and black',
  },
}
