import { create } from 'zustand'
import { callApi } from '../utils/callApi'
import { Order } from '../types/order'
import { NotificationService } from '../service/NotificationService'

interface Store {
  orders: Order[]
  getOrders: () => Promise<void>
  addOrder: (data: object, navigate: () => void) => Promise<void>
  updateOrder: (id: number, data: object, navigate: () => void) => Promise<void>
  deleteOrder: (id: number) => void
}

const useOrdersStore = create<Store>((set, get) => ({
  orders: [],
  getOrders: async () => {
    await callApi('/api/orders', 'GET').then((orders: Order[]) => {
      return set(() => ({ orders: orders }))
    })
  },
  addOrder: async (data, navigate) => {
    await callApi('/api/orders', 'POST', data)
      .then((res) => {
        NotificationService.showNotification(res.message, 'success')
        navigate()
        return set((state) => ({ orders: [...state.orders, res.order] }))
      })
      .catch((error) => {
        NotificationService.showNotification(error.message, 'error')
      })
  },
  updateOrder: async (id, data, navigate) => {
    await callApi(`/api/orders/${id}`, 'PUT', data).then((res) => {
      const index = get().orders.findIndex((order) => order.id === id)
      if (index !== -1) {
        NotificationService.showNotification(res.message, 'success')
        set((state) => {
          const orders = [...state.orders]
          orders[index] = res.order
          navigate()
          return { orders }
        })
      }
    })
  },
  deleteOrder: (id) => {
    callApi(`/api/orders/${id}`, 'DELETE').then((res) => {
      NotificationService.showNotification(res.message, 'success')

      return set((state) => ({
        orders: state.orders.filter((order) => order.id !== id),
      }))
    })
  },
}))

export default useOrdersStore
