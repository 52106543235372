import React from 'react'
import MInput from '../components/material/MInput'
import { styled } from '@mui/material'
import MButton from '../components/material/MButton'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { loginValidation } from '../utils/validation'
import useAuthStore from '../store/useAuthStore'
import { useNavigate } from 'react-router-dom'
import Logo from '../assets/AM_HAMA_logo_250_50.png'

const RootContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100vw',
  height: '100vh',
}))

const LoginFormContainer = styled('form')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}))

const Image = styled('img')(() => ({
  padding: '1rem 0',
  width: '300px',
  height: 'auto',
}))

const LoginPage = () => {
  const { login } = useAuthStore()
  const navigate = useNavigate()
  const { control, handleSubmit } = useForm({
    defaultValues: {
      login: '',
      password: '',
    },
    resolver: yupResolver(loginValidation),
    mode: 'all',
  })

  const onSubmit = async (data: { login: string; password: string }) => {
    await login(data).then(() => {
      navigate('/')
    })
  }

  return (
    <RootContainer>
      <LoginFormContainer onSubmit={handleSubmit(onSubmit)}>
        <Image src={Logo} alt="AM HAMA Logo" />
        <Controller
          name="login"
          control={control}
          render={({ field: { onChange, value }, formState: { errors } }) => (
            <MInput
              variant="outlined"
              label="Login"
              type="text"
              value={value}
              onChange={onChange}
              errorMessage={errors.login?.message}
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          render={({ field: { value, onChange }, formState: { errors } }) => (
            <MInput
              variant="outlined"
              label="Hasło"
              type="password"
              value={value}
              onChange={onChange}
              errorMessage={errors.password?.message}
            />
          )}
        />
        <MButton style={{ width: '250px' }} type="submit" variant="contained">
          Zaloguj się
        </MButton>
      </LoginFormContainer>
    </RootContainer>
  )
}

export default LoginPage
