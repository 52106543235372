import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import React from 'react'

interface DeleteOrderDialogProps {
  open: boolean
  closeDialog: () => void
  deleteOrder: () => void
}

const DeleteOrderDialog = ({
  open,
  closeDialog,
  deleteOrder,
}: DeleteOrderDialogProps) => {
  return (
    <Dialog open={open} onClose={() => closeDialog()}>
      <DialogTitle>Usuwanie zlecenia</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Czy na pewno chcesz usunąć to zlecenie?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => closeDialog()}>Anuluj</Button>
        <Button onClick={() => deleteOrder()}>Usuń</Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteOrderDialog
