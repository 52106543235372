import React, { useCallback, useRef, useState } from 'react'
import {
  Button,
  Grid2,
  IconButton,
  InputAdornment,
  styled,
  Typography,
} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useNavigate, useParams } from 'react-router-dom'
import MInput from '../components/material/MInput'
import { Controller, useForm } from 'react-hook-form'
import MDateTimePicker from '../components/material/MDateTimePicker'
import MButton from '../components/material/MButton'
import { yupResolver } from '@hookform/resolvers/yup'
import { addOrderValidation } from '../utils/validation'
import useOrdersStore from '../store/useOrdersStore'
import MCheckbox from '../components/material/MCheckbox'
import SyncIcon from '@mui/icons-material/Sync'
import useAuthStore from '../store/useAuthStore'
import { ralColorsPalette } from '../utils/ralColorsPalette'
import MAutocomplete, {
  AutoCompleteOption,
} from '../components/material/MAutocomplete'
import SignatureCanvas from 'react-signature-canvas'
import { useNotification } from '../context/NotificationContext'
import ConfirmPrintDialog from '../components/ConfirmPrintDialog'
import { Order } from '../types/order'

const Container = styled('div')(() => ({
  padding: '1rem',
  height: '100%',
}))

const HeaderContainer = styled(Grid2)(() => ({
  marginBottom: '1rem',
}))

const FormContainer = styled('form')(() => ({
  display: 'flex',
  flexDirection: 'column',
}))

const chekckboxOptions = [
  { value: 'yes', label: 'Tak' },
  { value: 'no', label: 'Nie' },
]

const AddOrderPage = () => {
  const navigate = useNavigate()
  const orderId = useParams().orderId
  const { orders, addOrder, updateOrder } = useOrdersStore()
  const { getDatFromGus } = useAuthStore()
  const signatureRef = useRef<SignatureCanvas | null>(null)
  const ralColorsOptions: AutoCompleteOption[] = []
  const { openNotification } = useNotification()
  const [openConfirmPrintDialog, setOpenConfirmPrintDialog] =
    useState<boolean>(false)

  const [elementToPrint, setElementToPrint] = useState<Order | null>(null)

  Object.keys(ralColorsPalette).forEach((key) => {
    ralColorsOptions.push({ value: key, label: key })
  })

  const editedObject = orders.find((order) => order.id === Number(orderId))

  const { control, handleSubmit, getValues, setValue, formState } = useForm({
    defaultValues: {
      name: editedObject?.name || '',
      taxId: editedObject?.taxId || '',
      postalCode: editedObject?.postalCode || '',
      city: editedObject?.city || '',
      road: editedObject?.road || '',
      ralColor: editedObject?.ralColor || '',
      ciNumber: editedObject?.ciNumber || '',
      date: editedObject?.date || Date.now(),
      phoneNumber: editedObject?.phoneNumber || '',
      base: editedObject?.base || 'no',
      sandblasting: editedObject?.sandblasting || 'no',
      signature: '',
    },
    resolver: yupResolver(addOrderValidation),
    mode: 'all',
  })

  const confirmPrintDialogControl = (order: Order | null, isOpen: boolean) => {
    setElementToPrint(order)
    setOpenConfirmPrintDialog(isOpen)
  }

  const getDetailsFromGus = async () => {
    await getDatFromGus(getValues('taxId') || '')
      .then((data) => {
        setValue('name', data.name)
        setValue('city', data.city)
        setValue('road', data.street)
        setValue('postalCode', data.postal_code)
      })
      .catch((error) => {
        openNotification(error.message, 'error')
      })
  }

  const clearSignatureCanvas = useCallback(() => {
    signatureRef?.current?.clear()
    setValue('signature', '', { shouldDirty: true, shouldValidate: true })
  }, [])

  return (
    <Container>
      <HeaderContainer
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon style={{ color: '#000000' }} />
        </IconButton>
        <Typography variant="h5">
          {orderId ? 'Edytuj zlecenie' : 'Dodaj zlecenie'}
        </Typography>
      </HeaderContainer>
      <FormContainer>
        <Grid2 container direction="row" gap={4}>
          <Grid2 direction="column" container sx={{ flex: 1 }}>
            <Typography style={{ marginBottom: '1rem' }} variant="h5">
              Kontrahent
            </Typography>
            <Controller
              name="taxId"
              control={control}
              render={({ field: { value, onChange } }) => (
                <MInput
                  fullWidth
                  variant="outlined"
                  label="NIP"
                  type="text"
                  value={value}
                  onChange={onChange}
                  slotProps={{
                    input: {
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            disabled={getValues('taxId') === ''}
                            onClick={() => getDetailsFromGus()}
                            edge="end"
                          >
                            <SyncIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    },
                  }}
                />
              )}
            />
            <Controller
              name="name"
              control={control}
              render={({
                field: { value, onChange },
                formState: { errors },
              }) => (
                <MInput
                  required
                  fullWidth
                  variant="outlined"
                  label="Imię Nazwisko / Nazwa firmy"
                  type="text"
                  value={value}
                  onChange={onChange}
                  errorMessage={errors.name?.message}
                />
              )}
            />
            <Controller
              name="postalCode"
              control={control}
              render={({
                field: { value, onChange },
                formState: { errors },
              }) => (
                <MInput
                  required
                  fullWidth
                  variant="outlined"
                  label="Kod pocztowy"
                  type="text"
                  value={value}
                  onChange={onChange}
                  errorMessage={errors.postalCode?.message}
                />
              )}
            />
            <Controller
              name="city"
              control={control}
              render={({
                field: { value, onChange },
                formState: { errors },
              }) => (
                <MInput
                  required
                  fullWidth
                  variant="outlined"
                  label="Miejscowość"
                  type="text"
                  value={value}
                  onChange={onChange}
                  errorMessage={errors.city?.message}
                />
              )}
            />
            <Controller
              name="road"
              control={control}
              render={({
                field: { value, onChange },
                formState: { errors },
              }) => (
                <MInput
                  required
                  fullWidth
                  variant="outlined"
                  label="Ulica"
                  type="text"
                  value={value}
                  onChange={onChange}
                  errorMessage={errors.road?.message}
                />
              )}
            />
            <Controller
              name="phoneNumber"
              control={control}
              render={({
                field: { value, onChange },
                formState: { errors },
              }) => (
                <MInput
                  required
                  fullWidth
                  variant="outlined"
                  label="Telefon kontaktowy"
                  type="tel"
                  value={value}
                  onChange={onChange}
                  errorMessage={errors.phoneNumber?.message}
                />
              )}
            />
          </Grid2>
          <Grid2 direction="column" container sx={{ flex: 1 }}>
            <Typography style={{ marginBottom: '1rem' }} variant="h5">
              Informacje dodatkowe
            </Typography>
            <Controller
              name="ralColor"
              control={control}
              render={({ field: { value } }) => (
                <MAutocomplete
                  fullWidth
                  value={value}
                  onChange={(value: string) =>
                    setValue('ralColor', value, {
                      shouldDirty: true,
                      shouldValidate: true,
                    })
                  }
                  options={ralColorsOptions}
                  label="Kolor RAL"
                />
              )}
            />
            <Controller
              name="ciNumber"
              control={control}
              render={({
                field: { value, onChange },
                formState: { errors },
              }) => (
                <MInput
                  required
                  fullWidth
                  variant="outlined"
                  label="PZ"
                  type="text"
                  value={value}
                  onChange={onChange}
                  errorMessage={errors.ciNumber?.message}
                />
              )}
            />
            <Controller
              name="date"
              control={control}
              render={({ field: { value, onChange } }) => (
                <MDateTimePicker
                  fullWidth
                  value={value as number}
                  onChange={onChange}
                />
              )}
            />
            <Grid2 container direction="row">
              <Grid2 style={{ flex: 1 }}>
                <Controller
                  name="base"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <MCheckbox
                      onChange={onChange}
                      id="base"
                      label="Podkład"
                      defaultValue={value}
                      options={chekckboxOptions}
                    />
                  )}
                />
              </Grid2>
              <Grid2 style={{ flex: 1 }}>
                <Controller
                  name="sandblasting"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <MCheckbox
                      onChange={onChange}
                      id="sandblasting"
                      label="Piaskowanie"
                      defaultValue={value}
                      options={chekckboxOptions}
                    />
                  )}
                />
              </Grid2>
            </Grid2>
            {!orderId && (
              <>
                <Typography variant="body2" color="textSecondary">
                  Podpis zlecającego
                </Typography>
                <Grid2 container justifyContent="flex-end">
                  <div
                    style={{
                      border: '1px solid black',
                      width: '402px',
                      height: '152px',
                    }}
                  >
                    <SignatureCanvas
                      ref={signatureRef}
                      penColor="black"
                      canvasProps={{ width: 400, height: 150 }}
                      onEnd={() => {
                        if (signatureRef.current) {
                          setValue(
                            'signature',
                            signatureRef.current
                              .getTrimmedCanvas()
                              .toDataURL('image/png'),
                            { shouldDirty: true, shouldValidate: true },
                          )
                        }
                      }}
                    />
                  </div>
                </Grid2>
                <Grid2 container direction="row" justifyContent="flex-end">
                  <Button onClick={clearSignatureCanvas}>Wyczyść podpis</Button>
                </Grid2>
              </>
            )}
          </Grid2>
        </Grid2>
        <Grid2
          style={{ position: 'absolute', bottom: '1rem', right: '1rem' }}
          container
          direction="row"
          justifyContent="flex-end"
        >
          <MButton
            disabled={!formState.isValid}
            variant="contained"
            onClick={handleSubmit(async (values) => {
              if (orderId) {
                await updateOrder(editedObject!.id, values, () => navigate('/'))
              } else {
                await addOrder(values, () =>
                  confirmPrintDialogControl(values as Order, true),
                )
              }
            })}
          >
            Zapisz
          </MButton>
        </Grid2>
      </FormContainer>
      <ConfirmPrintDialog
        open={openConfirmPrintDialog}
        closeDialog={() => confirmPrintDialogControl(null, false)}
        dataToPrint={elementToPrint}
        navigateTo={() => navigate('/')}
      />
    </Container>
  )
}

export default AddOrderPage
