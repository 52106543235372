import { AlertColor } from '@mui/material/Alert'

let enqueueNotification: (message: string, severity: AlertColor) => void

export const NotificationService = {
  setNotificationHandler: (
    handler: (message: string, severity: AlertColor) => void,
  ) => {
    enqueueNotification = handler
  },
  showNotification: (message: string, severity: AlertColor = 'info') => {
    if (enqueueNotification) {
      enqueueNotification(message, severity)
    } else {
      console.warn('Snackbar handler is not set.')
    }
  },
}
