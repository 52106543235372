// SnackbarContext.ts
import React, { createContext, useContext, useState, useCallback } from 'react'
import { Snackbar, Alert, AlertColor } from '@mui/material'
import { NotificationService } from '../service/NotificationService'

interface NotificationContextProps {
  openNotification: (message: string, severity: AlertColor) => void
}

export const NotificationContext = createContext<NotificationContextProps>({
  openNotification: () => {},
})

export const useNotification = () => {
  return useContext(NotificationContext)
}

const NotificationProvider = ({ children }: { children: React.ReactNode }) => {
  const [notificationData, setNotificationData] = useState({
    open: false,
    message: '',
    severity: 'success' as AlertColor,
  })

  const openNotification = useCallback(
    (message: string, severity: AlertColor = 'success') => {
      setNotificationData({ open: true, message, severity })
    },
    [],
  )

  const closeSnackbar = () => {
    setNotificationData((prev) => ({ ...prev, open: false }))
  }

  NotificationService.setNotificationHandler(openNotification)

  return (
    <NotificationContext.Provider value={{ openNotification }}>
      {children}
      <Snackbar
        open={notificationData.open}
        autoHideDuration={2000}
        onClose={closeSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          variant="filled"
          severity={notificationData.severity}
          sx={{ width: '100%' }}
        >
          {notificationData.message}
        </Alert>
      </Snackbar>
    </NotificationContext.Provider>
  )
}

export default NotificationProvider
