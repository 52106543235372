import React from 'react'
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material'

interface MCheckboxProps {
  id: string
  defaultValue: string
  options: { value: string; label: string }[]
  label: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void
}

const MCheckbox = ({
  id,
  defaultValue,
  options,
  label,
  onChange,
}: MCheckboxProps) => {
  return (
    <FormControl>
      <FormLabel id={id}>{label}</FormLabel>
      <RadioGroup
        row
        aria-labelledby={id}
        value={defaultValue}
        onChange={onChange}
      >
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio />}
            label={option.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}

export default MCheckbox
