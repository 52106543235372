import React, { useEffect } from 'react'
import { styled } from '@mui/material'
import MainNavigation from './MainNavigation'
import { Outlet } from 'react-router-dom'
import usePrinterStore from '../store/usePrinterStore'

const RootContainer = styled('div')(() => ({
  position: 'relative',
  overflow: 'hidden',
  display: 'flex',
  minHeight: '100%',
  minWidth: '100%',
}))

const InnerContainer = styled('div')(() => ({
  minHeight: '100%',
  width: '100%',
}))

const DefaultLayout = () => {
  const { getPrinterStatus, printerStatuses } = usePrinterStore()

  useEffect(() => {
    getPrinterStatus()

    let interval = undefined

    if (printerStatuses?.printerStatusMessage) {
      interval = setInterval(() => {
        getPrinterStatus()
      }, 15000)
    }

    return () => clearInterval(interval)
  }, [])

  return (
    <RootContainer>
      <MainNavigation />
      <InnerContainer>
        <Outlet />
      </InnerContainer>
    </RootContainer>
  )
}

export default DefaultLayout
