import axios from 'axios'

export const callApi = async (
  url: string,
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' = 'GET',
  body?: unknown,
  successMessage?: string,
) => {
  const showMessage = (message: string | undefined) => {
    if (message) {
      console.log(message)
    }
  }

  try {
    if (method === 'GET') {
      const { data } = await axios.get(url)
      showMessage(successMessage)
      return data
    } else if (method === 'POST') {
      const { data: postData } = await axios.post(url, body)
      showMessage(successMessage)
      return postData
    } else if (method === 'PUT') {
      const { data: putData } = await axios.put(url, body)
      showMessage(successMessage)
      return putData
    } else if (method === 'PATCH') {
      const { data: patchData } = await axios.patch(url, body)
      showMessage(successMessage)
      return patchData
    } else if (method === 'DELETE') {
      const { data: deleteData } = await axios.delete(url, { data: body })
      showMessage(successMessage)
      return deleteData
    }
  } catch (error) {
    // @ts-expect-error error dont have response
    throw error.response.data || error.message
  }
}
