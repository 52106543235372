import { create } from 'zustand'
import { Order } from '../types/order'
import { callApi } from '../utils/callApi'
import { NotificationService } from '../service/NotificationService'

interface PrinterStatuses {
  paperStatusMessage: boolean
  printerStatusMessage: boolean
  ribbonStatusMessage: boolean
}

interface ObjectToPrint extends Order {
  numberOfCopy: string
  signature?: string
}

interface Store {
  printLabel: (data: ObjectToPrint) => Promise<void>
  getPrinterStatus: () => Promise<void>
  printerStatuses: PrinterStatuses | null
}

const usePrinterStore = create<Store>((set) => ({
  printerStatuses: null,
  printLabel: async (data) => {
    await callApi('/api/print-label', 'POST', data).then((res) => {
      NotificationService.showNotification(res.message, 'success')
    })
  },
  getPrinterStatus: async () => {
    await callApi(`/api/printer-status`, 'GET')
      .then((response) => {
        return set(() => ({ printerStatuses: response }))
      })
      .catch((error) => {
        console.log(error)
        NotificationService.showNotification(error.message, 'error')
      })
  },
}))

export default usePrinterStore
