import React, { useEffect, useRef, useState } from 'react'
import useOrdersStore from '../store/useOrdersStore'
import {
  Grid2,
  IconButton,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material'
import PrintIcon from '@mui/icons-material/Print'
import VisibilityIcon from '@mui/icons-material/Visibility'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner'
import MButton from '../components/material/MButton'
import MInput from '../components/material/MInput'
import { filter, includes, isString, some, toLower } from 'lodash'
import { useLocation, useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import DeleteOrderDialog from '../components/DeleteOrderDialog'
import ConfirmPrintDialog from '../components/ConfirmPrintDialog'
import { Order } from '../types/order'
import usePrinterStore from '../store/usePrinterStore'

const Container = styled('div')(() => ({
  padding: '1rem',
  height: '100%',
}))

const HeaderContainer = styled(Grid2)(() => ({
  marginBottom: '1rem',
}))

const Cell = styled(TableCell)(() => ({
  padding: '0.25rem 1rem',
}))

const Icon = styled(IconButton)(() => ({
  padding: '0.25rem 0.25rem',
}))

const OrdersPage = () => {
  const tableContainerRef = useRef<HTMLDivElement>(null)
  const rowsPerPage = 10
  const [page, setPage] = useState<number>(0)
  const [search, setSearch] = useState<string>('')
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false)
  const [openConfirmPrintDialog, setOpenConfirmPrintDialog] =
    useState<boolean>(false)
  const [idToDelete, setIdToDelete] = useState<number | null>(null)
  const [elementToPrint, setElementToPrint] = useState<Order | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const navigate = useNavigate()
  const location = useLocation()
  const { printerStatuses } = usePrinterStore()

  const getPageFromQuery = () => {
    const params = new URLSearchParams(location.search)
    return parseInt(params.get('page') || '0')
  }

  const { orders, getOrders, deleteOrder } = useOrdersStore()

  useEffect(() => {
    if (!orders.length) {
      setIsLoading(true)
      getOrders().finally(() => {
        setIsLoading(false)
      })
    }
  }, [])

  useEffect(() => {
    const page = getPageFromQuery()
    setPage(page)
  }, [location.search])

  const handleChangePage = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    page: number,
  ) => {
    setPage(page)
    const params = new URLSearchParams(location.search)
    params.set('page', String(page))
    navigate(`?${params.toString()}`)
  }

  const openDetails = (id: number) => {
    navigate(`/order/${id}`)
  }

  const filteredOrders = filter(orders, (obj) =>
    some(
      obj,
      (value) => isString(value) && includes(toLower(value), toLower(search)),
    ),
  )

  const addNew = () => {
    navigate('add-order')
  }

  const editOrder = (id: number) => {
    navigate(`/edit-order/${id}`)
  }

  const openQrScanner = () => {
    navigate('scan-qr')
  }

  const deleteDialogControl = (id: number | null, isOpen: boolean) => {
    setIdToDelete(id)
    setOpenDeleteDialog(isOpen)
  }
  const confirmPrintDialogControl = (order: Order | null, isOpen: boolean) => {
    setElementToPrint(order)
    setOpenConfirmPrintDialog(isOpen)
  }

  const isPrinterConnected = () => {
    return (
      !printerStatuses?.printerStatusMessage ||
      !printerStatuses?.paperStatusMessage ||
      !printerStatuses?.ribbonStatusMessage
    )
  }

  return (
    <Container>
      <HeaderContainer container direction="row" justifyContent="space-between">
        <Typography variant="h5">Zlecenia</Typography>
        <Grid2 container direction="row" gap={4}>
          <MButton
            style={{ width: '50px' }}
            variant="contained"
            onClick={() => openQrScanner()}
          >
            <QrCodeScannerIcon />
          </MButton>
          <MButton
            style={{ width: '50px' }}
            variant="contained"
            onClick={() => addNew()}
          >
            <AddIcon />
          </MButton>
        </Grid2>
      </HeaderContainer>
      <MInput
        searchInput
        sx={{ '&: div': { backgroundColor: 'transparent' } }}
        variant="standard"
        placeholder="Wyszukaj..."
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <TableContainer
        style={{ maxHeight: 'calc(100dvh - 184px)', overflow: 'auto' }}
        component={Paper}
        ref={tableContainerRef}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow style={{ height: '60px' }}>
              <TableCell width="50px">Id</TableCell>
              <TableCell width="30%" align="left">
                Kontrahent
              </TableCell>
              <TableCell align="left">Kolor RAL</TableCell>
              <TableCell align="left">Data przyjęcia</TableCell>
              <TableCell align="left">Numer WZ</TableCell>
              <TableCell width="128px" align="right">
                Akcje
              </TableCell>
            </TableRow>
          </TableHead>
          {!isLoading && (
            <TableBody>
              {filteredOrders
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    style={{ height: '50px' }}
                  >
                    <Cell component="th" scope="row">
                      {row.id}
                    </Cell>
                    <Cell align="left">{row.name}</Cell>
                    <Cell align="left">{row.ralColor}</Cell>
                    <Cell align="left">
                      {dayjs(row.date).format('YYYY-MM-DD HH:ss')}
                    </Cell>
                    <Cell align="left">{row.ciNumber}</Cell>
                    <Cell align="center">
                      <Grid2
                        container
                        direction="row"
                        justifyContent="flex-end"
                      >
                        {!isPrinterConnected() && (
                          <Icon
                            onClick={() => confirmPrintDialogControl(row, true)}
                          >
                            <PrintIcon
                              style={{
                                color: '#000000',
                              }}
                            />
                          </Icon>
                        )}
                        <Icon onClick={() => openDetails(row.id)}>
                          <VisibilityIcon style={{ color: '#000000' }} />
                        </Icon>
                        <Icon onClick={() => editOrder(row.id)}>
                          <EditIcon style={{ color: '#000000' }} />
                        </Icon>
                        <Icon onClick={() => deleteDialogControl(row.id, true)}>
                          <DeleteIcon style={{ color: '#000000' }} />
                        </Icon>
                      </Grid2>
                    </Cell>
                  </TableRow>
                ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[rowsPerPage]}
        component="div"
        count={orders.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
      />
      <ConfirmPrintDialog
        open={openConfirmPrintDialog}
        closeDialog={() => confirmPrintDialogControl(null, false)}
        dataToPrint={elementToPrint}
      />
      <DeleteOrderDialog
        open={openDeleteDialog}
        closeDialog={() => deleteDialogControl(null, false)}
        deleteOrder={() => {
          if (idToDelete) deleteOrder(idToDelete)
          deleteDialogControl(null, false)
        }}
      />
    </Container>
  )
}

export default OrdersPage
