import React from 'react'
import { Scanner } from '@yudiel/react-qr-scanner'

const QRScannerPage = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div style={{ width: '100dvh', height: '100dvh' }}>
        <Scanner
          constraints={{
            facingMode: 'environment',
          }}
          onScan={(e) => {
            window.location.href = e[0].rawValue
          }}
        />
      </div>
    </div>
  )
}

export default QRScannerPage
