import React from 'react'
import { styled, TextField, TextFieldProps } from '@mui/material'

const CustomInput = styled(TextField)(() => ({
  width: '300px',
  height: '90px',
  '& div': {
    backgroundColor: '#ffffff',
  },
}))

const SearchInput = styled(TextField)(() => ({
  width: '300px',
  marginBottom: '1rem',
  backgroundColor: 'transparent',
}))

interface MInputProps {
  fullWidth?: boolean
  errorMessage?: string
  searchInput?: boolean
}

const MInput = (props: MInputProps & TextFieldProps) => {
  if (props.searchInput) {
    return (
      <SearchInput
        error={!!props.errorMessage}
        helperText={props.errorMessage}
        style={props.fullWidth ? { width: '100%' } : {}}
        {...props}
      />
    )
  }

  return (
    <CustomInput
      error={!!props.errorMessage}
      helperText={props.errorMessage}
      style={props.fullWidth ? { width: '100%' } : {}}
      {...props}
    />
  )
}

export default MInput
