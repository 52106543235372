import React from 'react'
import { DateTimePicker } from '@mui/x-date-pickers'
import { styled } from '@mui/material'
import dayjs from 'dayjs'

interface MDateTimePickerProps {
  value: number
  onChange: (value: number) => void
  fullWidth?: boolean
}

const CustomDateTimePicker = styled(DateTimePicker)(() => ({
  width: '300px',
  marginBottom: '1rem',
  backgroundColor: '#ffffff',
}))

const MDateTimePicker = ({
  value,
  onChange,
  fullWidth,
}: MDateTimePickerProps) => {
  return (
    <CustomDateTimePicker
      label="Basic date time picker"
      orientation="landscape"
      ampm={false}
      value={dayjs(value)}
      onChange={(value) => onChange(dayjs(value).valueOf())}
      sx={fullWidth ? { width: '100%' } : {}}
    />
  )
}

export default MDateTimePicker
