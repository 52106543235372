import axios, { AxiosResponse } from 'axios'

export const getServerUrl = (path: string) => {
  return window.location.origin + '/' + path.replace(/^\//, '')
}

export const toDataURL = (url: string) =>
  axios.get(url, { responseType: 'blob' }).then((response: AxiosResponse) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.onerror = reject
      reader.readAsDataURL(response.data)
    })
  })
