import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import NotificationProvider from './context/NotificationContext'
import { styled, Typography } from '@mui/material'
import ScreenRotationIcon from '@mui/icons-material/ScreenRotation'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

const Container = styled('div')(() => ({
  backgroundColor: '#f2f8fc',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  gap: '1rem',
}))

function App() {
  const [orientation, setOrientation] = useState<string>(
    window.innerHeight > window.innerWidth ? 'portrait' : 'landscape',
  )

  useEffect(() => {
    const handleResize = () => {
      const vh = window.innerHeight * 0.01

      document.documentElement.style.setProperty('--vh', `${vh}px`)

      setOrientation(
        window.innerHeight > window.innerWidth ? 'portrait' : 'landscape',
      )
    }

    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  if (orientation === 'portrait') {
    return (
      <Container className="app">
        <Typography variant="h5">
          Aplikacja działa tylko w orientaji poziomej
        </Typography>
        <ScreenRotationIcon style={{ fontSize: '30px' }} />
      </Container>
    )
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <NotificationProvider>
        <Outlet />
      </NotificationProvider>
    </LocalizationProvider>
  )
}

export default App
